import React from 'react'

export const plant = {
  path: (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.5 0C6.29086 0 4.5 1.79086 4.5 4C4.5 6.20914 6.29086 8 8.5 8C9.2042 8 9.8659 7.81803 10.4406 7.49854C10.4582 7.52302 10.4759 7.54799 10.4937 7.57342C10.8916 8.14186 11.3205 8.90362 11.5302 9.74249C11.7598 10.6607 11.8796 11.7199 11.9404 12.5712C11.9512 12.7226 11.9601 12.8663 11.9674 13H7.5C5.84315 13 4.5 14.3431 4.5 16V17C4.5 18.1046 5.39543 19 6.5 19V21C6.5 22.6569 7.84315 24 9.5 24H17.5C19.1569 24 20.5 22.6569 20.5 21V19C21.6046 19 22.5 18.1046 22.5 17V16C22.5 14.3431 21.1569 13 19.5 13H13.9701C13.9613 12.8257 13.95 12.6338 13.9353 12.4287C13.9256 12.2921 13.9143 12.1487 13.9012 12C13.9938 11.8981 14.1273 11.7487 14.3157 11.5334C14.6576 11.1427 15.1833 10.5311 15.9777 9.58563C16.424 9.84893 16.9443 10 17.5 10C19.1569 10 20.5 8.65685 20.5 7C20.5 5.34315 19.1569 4 17.5 4C15.8431 4 14.5 5.34315 14.5 7C14.5 7.35898 14.5631 7.70323 14.6787 8.02228C14.2043 8.58843 13.819 9.04376 13.5073 9.40883C13.4953 9.35815 13.4831 9.30767 13.4705 9.25742C13.1802 8.09629 12.6092 7.10805 12.1321 6.42649C12.0525 6.31272 11.9746 6.20635 11.9001 6.10797C12.2804 5.4959 12.5 4.77359 12.5 4C12.5 1.79086 10.7091 0 8.5 0ZM6.5 4C6.5 2.89543 7.39543 2 8.5 2C9.60457 2 10.5 2.89543 10.5 4C10.5 5.10457 9.60457 6 8.5 6C7.39543 6 6.5 5.10457 6.5 4ZM16.5 7C16.5 6.44772 16.9477 6 17.5 6C18.0523 6 18.5 6.44772 18.5 7C18.5 7.55228 18.0523 8 17.5 8C16.9477 8 16.5 7.55228 16.5 7ZM18.5 19H8.5V21C8.5 21.5523 8.94772 22 9.5 22H17.5C18.0523 22 18.5 21.5523 18.5 21V19ZM6.5 16C6.5 15.4477 6.94772 15 7.5 15H19.5C20.0523 15 20.5 15.4477 20.5 16V17H6.5V16Z"
      fill="currentColor"
    />
  ),
  viewBox: '0 0 25 25',
}
