import React from 'react'

export const relock = {
  path: (
    <>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.811 0.778322C8.02191 0.735097 8.20835 0.921532 8.16512 1.13245L7.10549 6.30282C7.05751 6.53692 6.76844 6.62369 6.59946 6.45472L5.25313 5.10839C3.5457 6.90303 2.49927 9.32826 2.49927 12C2.49927 17.5228 6.97642 22 12.4993 22C18.0221 22 22.4993 17.5228 22.4993 12C22.4993 6.47712 18.0221 1.99997 12.4993 1.99997C11.947 1.99997 11.4993 1.55225 11.4993 0.999969C11.4993 0.447685 11.947 -3.05176e-05 12.4993 -3.05176e-05C19.1267 -3.05176e-05 24.4993 5.37255 24.4993 12C24.4993 18.6274 19.1267 24 12.4993 24C5.87185 24 0.499268 18.6274 0.499268 12C0.499268 8.7769 1.77126 5.84882 3.83855 3.69381L2.48872 2.34398C2.31975 2.17501 2.40653 1.88593 2.64063 1.83796L7.811 0.778322ZM10.511 8.99997C10.511 7.8954 11.4064 6.99997 12.511 6.99997C13.6155 6.99997 14.5109 7.89527 14.511 8.99973H14.4983V9.99973H16.4983V8.99997H16.511C16.511 6.79083 14.7201 4.99997 12.511 4.99997C10.3019 4.99997 8.51112 6.7907 8.51099 8.99973H8.49829V9.99973H10.4983V8.99997H10.511ZM8.49829 9.99997C7.39372 9.99997 6.49829 10.8954 6.49829 12V16C6.49829 17.1045 7.39372 18 8.49829 18H16.4983C17.6029 18 18.4983 17.1045 18.4983 16V12C18.4983 10.8954 17.6029 9.99997 16.4983 9.99997H8.49829ZM8.49829 12H16.4983V16H8.49829V12ZM13.511 14.0002C13.511 14.5525 13.0633 15.0002 12.511 15.0002C11.9587 15.0002 11.511 14.5525 11.511 14.0002C11.511 13.4479 11.9587 13.0002 12.511 13.0002C13.0633 13.0002 13.511 13.4479 13.511 14.0002Z"
        fill="currentColor"
      />
    </>
  ),
  viewBox: '0 0 25 24',
}
