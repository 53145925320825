import React from 'react'

export const yield_loop = {
  path: (
    <>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 8C9.79086 8 8 9.79086 8 12C8 14.2091 9.79086 16 12 16C14.2091 16 16 14.2091 16 12C16 9.79086 14.2091 8 12 8ZM6 12C6 8.68629 8.68629 6 12 6C15.3137 6 18 8.68629 18 12C18 15.3137 15.3137 18 12 18C8.68629 18 6 15.3137 6 12Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.7057 9.70765C22.6843 10.0658 22.3037 10.2856 21.9828 10.125L17.8662 8.06518C17.5101 7.88696 17.4951 7.38415 17.84 7.18502L19.0952 6.4603C16.3788 2.97235 11.4379 1.93247 7.50066 4.20565C5.32012 5.46458 3.86634 7.4963 3.28444 9.75762L1.34754 9.25921C2.05783 6.49895 3.83596 4.01206 6.50066 2.4736C11.3958 -0.352618 17.5603 1.01124 20.841 5.45236L22.231 4.64987C22.5759 4.45073 23.0039 4.71512 22.9801 5.11269L22.7057 9.70765ZM16.5007 19.7941C18.6252 18.5675 20.0597 16.6074 20.6702 14.4161L22.5969 14.9529C21.8515 17.6281 20.0967 20.0273 17.5007 21.5262C12.3117 24.522 5.69657 22.8097 2.60168 17.7148L1.26967 18.4839C0.924757 18.683 0.496816 18.4186 0.52056 18.021L0.794999 13.4261C0.816392 13.0679 1.19695 12.8482 1.51785 13.0087L5.63442 15.0685C5.9906 15.2468 6.0056 15.7496 5.66068 15.9487L4.33427 16.7145C6.87638 20.853 12.2684 22.2376 16.5007 19.7941Z"
        fill="currentColor"
      />
    </>
  ),
  viewBox: '0 0 24 24',
}
