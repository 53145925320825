import React from 'react'

export const sparks_empty = {
  path: (
    <>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.99985 0.604401C6.25333 0.604401 6.93957 0.733261 7.10832 1.46391C7.18762 1.80723 7.30505 2.23045 7.46571 2.62698C7.63248 3.03857 7.81455 3.34217 7.98611 3.51374C8.15767 3.6853 8.46128 3.86737 8.87286 4.03413C9.2694 4.1948 9.69262 4.31223 10.0359 4.39153C10.7666 4.56028 10.8954 5.24652 10.8954 5.5C10.8954 5.75348 10.7666 6.43972 10.0359 6.60847C9.69262 6.68777 9.2694 6.8052 8.87286 6.96587C8.46128 7.13263 8.15767 7.3147 7.98611 7.48626C7.81455 7.65783 7.63248 7.96143 7.46571 8.37302C7.30505 8.76955 7.18762 9.19277 7.10832 9.53609C6.93957 10.2667 6.25333 10.3956 5.99985 10.3956C5.74636 10.3956 5.06013 10.2667 4.89137 9.53609C4.81208 9.19277 4.69465 8.76955 4.53398 8.37302C4.36722 7.96143 4.18515 7.65783 4.01358 7.48626C3.84202 7.3147 3.53841 7.13263 3.12683 6.96587C2.7303 6.8052 2.30708 6.68777 1.96376 6.60847C1.23311 6.43972 1.10425 5.75348 1.10425 5.5C1.10425 5.24652 1.23311 4.56028 1.96376 4.39153C2.30708 4.31223 2.7303 4.1948 3.12683 4.03413C3.53841 3.86737 3.84202 3.6853 4.01358 3.51374C4.18515 3.34217 4.36722 3.03857 4.53398 2.62698C4.69465 2.23045 4.81208 1.80723 4.89137 1.46391C5.06013 0.733261 5.74636 0.604401 5.99985 0.604401ZM5.99985 4.17374C5.84138 4.44246 5.65268 4.70306 5.4278 4.92795C5.20291 5.15284 4.94231 5.34153 4.67359 5.5C4.94231 5.65847 5.20291 5.84716 5.4278 6.07205C5.65268 6.29693 5.84138 6.55754 5.99985 6.82626C6.15831 6.55754 6.34701 6.29693 6.5719 6.07205C6.79678 5.84716 7.05739 5.65847 7.32611 5.5C7.05739 5.34153 6.79678 5.15284 6.5719 4.92795C6.34701 4.70306 6.15831 4.44246 5.99985 4.17374Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.99991 11.6054C10.2737 11.6054 10.9481 11.7512 11.1066 12.4767C11.1984 12.8966 11.3435 13.4532 11.5516 13.9865C11.7665 14.5374 12.0147 14.9738 12.2704 15.2295C12.5262 15.4853 12.9626 15.7335 13.5135 15.9484C14.0468 16.1565 14.6034 16.3016 15.0233 16.3933C15.7488 16.5519 15.8946 17.2263 15.8946 17.5001C15.8946 17.7738 15.7488 18.4482 15.0233 18.6068C14.6034 18.6986 14.0468 18.8436 13.5135 19.0517C12.9626 19.2666 12.5262 19.5148 12.2704 19.7706C12.0147 20.0263 11.7665 20.4628 11.5516 21.0136C11.3435 21.5469 11.1984 22.1035 11.1066 22.5235C10.9481 23.249 10.2737 23.3948 9.99991 23.3948C9.72616 23.3948 9.05173 23.249 8.89319 22.5235C8.80142 22.1035 8.65633 21.5469 8.44827 21.0136C8.23335 20.4628 7.98514 20.0263 7.72939 19.7706C7.47365 19.5148 7.03722 19.2666 6.48634 19.0517C5.95306 18.8436 5.39647 18.6986 4.97652 18.6068C4.251 18.4482 4.10522 17.7738 4.10522 17.5001C4.10522 17.2263 4.251 16.5519 4.97652 16.3933C5.39647 16.3016 5.95306 16.1565 6.48634 15.9484C7.03722 15.7335 7.47365 15.4853 7.72939 15.2295C7.98514 14.9738 8.23335 14.5374 8.44827 13.9865C8.65633 13.4532 8.80142 12.8966 8.89319 12.4767C9.05173 11.7512 9.72616 11.6054 9.99991 11.6054ZM9.99991 15.4156C9.77764 15.8547 9.49735 16.29 9.14361 16.6438C8.78986 16.9975 8.35459 17.2778 7.91541 17.5001C8.35459 17.7223 8.78986 18.0026 9.14361 18.3564C9.49735 18.7101 9.77764 19.1454 9.99991 19.5846C10.2222 19.1454 10.5025 18.7101 10.8562 18.3564C11.21 18.0026 11.6452 17.7223 12.0844 17.5001C11.6452 17.2778 11.21 16.9975 10.8562 16.6438C10.5025 16.29 10.2222 15.8547 9.99991 15.4156Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.6072 5.47066C19.4441 4.74291 18.7645 4.60465 18.4997 4.60464C18.2349 4.60464 17.5553 4.74291 17.3922 5.47066C17.3065 5.85324 17.1751 6.34327 16.9906 6.80802C16.7996 7.28919 16.5845 7.65848 16.3713 7.8717C16.1581 8.08492 15.7888 8.30002 15.3076 8.49099C14.8429 8.67545 14.3528 8.80688 13.9703 8.89261C13.2425 9.05569 13.1042 9.73525 13.1042 10.0001C13.1042 10.2649 13.2425 10.9445 13.9703 11.1076C14.3528 11.1933 14.8429 11.3247 15.3076 11.5092C15.7888 11.7002 16.1581 11.9153 16.3713 12.1285C16.5845 12.3417 16.7996 12.711 16.9906 13.1922C17.1751 13.6569 17.3065 14.1469 17.3922 14.5295C17.5553 15.2573 18.2349 15.3955 18.4997 15.3955C18.7645 15.3955 19.4441 15.2573 19.6072 14.5295C19.6929 14.1469 19.8243 13.6569 20.0088 13.1922C20.1998 12.711 20.4149 12.3417 20.6281 12.1285C20.8413 11.9153 21.2106 11.7002 21.6918 11.5092C22.1565 11.3247 22.6465 11.1933 23.0291 11.1076C23.7569 10.9445 23.8951 10.2649 23.8951 10.0001C23.8951 9.73525 23.7569 9.05569 23.0291 8.89261C22.6465 8.80688 22.1565 8.67545 21.6918 8.49099C21.2106 8.30002 20.8413 8.08492 20.6281 7.8717C20.4149 7.65848 20.1998 7.28919 20.0088 6.80802C19.8243 6.34327 19.6929 5.85324 19.6072 5.47066ZM17.7855 9.28591C18.0738 8.99762 18.3085 8.65291 18.4997 8.30087C18.6909 8.65291 18.9256 8.99762 19.2139 9.28591C19.5022 9.5742 19.8469 9.80888 20.1989 10.0001C19.8469 10.1913 19.5022 10.426 19.2139 10.7143C18.9256 11.0026 18.6909 11.3473 18.4997 11.6993C18.3085 11.3473 18.0738 11.0026 17.7855 10.7143C17.4972 10.426 17.1525 10.1913 16.8005 10.0001C17.1525 9.80888 17.4972 9.5742 17.7855 9.28591Z"
        fill="currentColor"
      />
    </>
  ),
  viewBox: '0 0 25 24',
}
