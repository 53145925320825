import React from 'react'
export const clear_selection = {
  path: (
    <>
      <circle cx="13" cy="13" r="13" fill="#EDEDFF" key="Mav3A6dq-" />
      <path
        d="M8.05071 17.9497C7.66018 17.5592 7.66018 16.926 8.05071 16.5355L16.536 8.05022C16.9265 7.65969 17.5597 7.65969 17.9502 8.05022C18.3407 8.44074 18.3407 9.07391 17.9502 9.46443L9.46492 17.9497C9.0744 18.3402 8.44123 18.3402 8.05071 17.9497Z"
        fill="#575CFE"
        key="Th-qrvnjOX"
      />
      <path
        d="M17.9502 17.9497C17.5597 18.3402 16.9265 18.3402 16.536 17.9497L8.05071 9.46443C7.66018 9.07391 7.66018 8.44074 8.05071 8.05022C8.44123 7.65969 9.0744 7.65969 9.46492 8.05022L17.9502 16.5355C18.3407 16.926 18.3407 17.5592 17.9502 17.9497Z"
        fill="#575CFE"
        key="8BNr0RvXK9"
      />
    </>
  ),
  viewBox: '0 0 26 26',
}
